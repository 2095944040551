import type { UIMatch_SingleFetch } from '@remix-run/react';
import type { MetaMatch } from '@remix-run/react/dist/routeModules';
import type { Storefront } from '@storefront/util/medusa/resources/storefronts';
import type { MetaArgs, MetaDescriptor, MetaFunction } from '@vercel/remix';
import truncate from 'lodash/truncate';
import type { RootLoader } from '~/utils/server/root.server';

const applyMetaTemplates = ({ matches }: MetaArgs<unknown, MetaDescriptor>, mergedMeta: MetaDescriptor[]) => {
  const rootMatch = matches[0] as UIMatch_SingleFetch<RootLoader>;
  const storefrontSettings = rootMatch.data?.storefrontSettings;

  return mergedMeta.map((meta) => {
    if ('title' in meta) {
      const title = meta.title as string;
      if (title === storefrontSettings?.name) {
        return {
          title,
        };
      }
      return {
        title: `${title} | ${storefrontSettings?.name}`,
      };
    }

    if ('name' in meta && meta.name === 'description') {
      const content = meta.content as string;

      return {
        name: 'description',
        content: truncate(`${content}`, { length: 200, separator: ' ' }),
      };
    }

    if ('property' in meta && meta.property === 'og:title') {
      const content = meta.content as string;
      return {
        property: 'og:title',
        content: `${content} | ${storefrontSettings?.name}`,
      };
    }

    if ('property' in meta && meta.property === 'og:description') {
      const content = meta.content as string;

      return {
        property: 'og:description',
        content: truncate(`${content}`, { length: 200, separator: ' ' }),
      };
    }

    return meta;
  });
};

const filterEmptyMeta = (meta: MetaDescriptor[]) =>
  meta.filter(
    (meta) =>
      ('title' in meta && !!meta.title) || ('name' in meta && !!meta.content) || ('property' in meta && !!meta.content),
  );

const mergeMetaArrays = (prevMeta: MetaDescriptor[], nextMeta: MetaDescriptor[]) => {
  const mergedMeta = [...prevMeta];
  // Filter out empty meta before merging
  const filteredNextMeta = filterEmptyMeta(nextMeta);

  for (const override of filteredNextMeta) {
    // Find the matching index
    const index = mergedMeta.findIndex(
      (meta) =>
        ('title' in meta && 'title' in override) ||
        ('name' in meta && 'name' in override && meta.name === override.name) ||
        ('property' in meta && 'property' in override && meta.property === override.property),
    );

    if (index !== -1) {
      // The there is a match, remove it
      mergedMeta.splice(index, 1);
    }

    // Append the override
    mergedMeta.push(override);
  }

  return mergedMeta;
};

/**
 * A helper function to merge meta from parent routes with the current route.
 *
 * @see https://gist.github.com/ryanflorence/ec1849c6d690cfbffcb408ecd633e069
 */
export const mergeMeta =
  (...overrideFuncs: MetaFunction[]): MetaFunction =>
  (arg) =>
    applyMetaTemplates(
      arg,
      overrideFuncs.reduce(
        (acc: MetaDescriptor[], override: MetaFunction) => mergeMetaArrays(acc, override(arg)),
        [] as MetaDescriptor[],
      ),
    );

/**
 * Gets the merged meta from the parent routes.
 */
export const getParentMeta: MetaFunction = ({ matches }) =>
  matches.reduce((acc, match: MetaMatch) => mergeMetaArrays(acc, match.meta || []), [] as MetaDescriptor[]);

/**
 * Gets the common meta most routes will use.
 */
export const getCommonMeta: MetaFunction = ({ matches, location, data }) => {
  const currentMatch: MetaMatch = matches[matches.length - 1];

  const storefrontSettings = (data as { storefrontSettings: Storefront })?.storefrontSettings;

  if (!storefrontSettings) return [];

  const url = `${(currentMatch?.data as { canonicalUrl: string })?.canonicalUrl}${location.pathname}`;

  return [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, height=device-height, user-scalable=0',
    },
    { property: 'og:url', content: url },
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: storefrontSettings.name },
  ];
};
